body {
  height: calc(var(--vh, 1vh) * 100) !important;
  overflow: hidden;
}
html {
  height: calc(var(--vh, 1vh) * 100) !important;
}
body #root {
  overflow-x: hidden;
  overflow-y: hidden;
  height: calc(calc(var(--vh, 1vh) * 100) - 0px);
}
body.no-scrolling .mainContent {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}
.couponStatusLabel {
  display: inline-block;
  min-height: 21px;
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.couponStatusCol {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  flex-direction: column;
}
.text--light {
  font-weight: lighter;
}
.text--bold {
  font-weight: bold;
}
.text--italic {
  font-style: italic;
}
.text--outlined {
  position: relative;
  white-space: nowrap;
}
.text--outlined:before {
  content: '';
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  border-bottom-color: inherit;
}
.ui.divider.clearBottom {
  margin-bottom: 0;
}
.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin-top: 1em;
  margin-bottom: 1em;
}
.expirationDateField > .field {
  margin-bottom: 0 !important;
}
.expirationDateField > .field > .field {
  margin-bottom: 0 !important;
}
.expirationDateField > .field > .field > .field {
  margin-bottom: 0 !important;
}
.ui.dropdown > .menu {
  background-color: #ffffff !important;
}
.ui.menu .ui.dropdown .menu > .item.dropdownItemDivider {
  padding: 0 !important;
}
.payoutSettingsForm {
  position: relative;
}
.payoutSettingsForm .field:not(:last-of-type) {
  margin-bottom: 1rem;
}
.ui.input input:focus + .customicon > svg * {
  fill: rgba(0, 0, 0, 0.75);
}
.ui.input .customicon {
  width: 3rem;
  height: 100%;
  position: absolute;
  left: 0;
  padding: 0 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ui.input .customicon > svg {
  width: 100%;
  height: 100%;
}
.ui.input .customicon > svg * {
  transition: all 0.3s ease-out;
  fill: rgba(0, 0, 0, 0.5);
}
.rootWrapper {
  margin-top: 60px;
}
.rootWrapper.checkout .sideBarToggle,
.rootWrapper.store .sideBarToggle {
  display: none;
}
.rootWrapper.checkout .titlebar,
.rootWrapper.store .titlebar {
  display: block;
}
.rootWrapper.pageScrolled .mainNavBar {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
}
.rootWrapper.pageScrolled .mainNavBar .pageLabel span {
  transform: translateY(0);
}
.rootWrapper.splash {
  margin-top: 0;
}
.rootWrapper.splash .mainContent {
  height: calc(calc(var(--vh, 1vh) * 100));
  overflow: hidden;
}
.rootWrapper.create {
  margin-top: 0;
}
.rootWrapper.create .mainNavBar {
  background-color: rgba(255, 255, 255, 0) !important;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(224, 224, 224, 0);
  border-bottom: none;
  transition: background-color 0.5s ease-out, border 0.5s ease-out;
}
.rootWrapper.create .mainContent {
  height: calc(calc(var(--vh, 1vh) * 100));
}
@media only screen and (max-device-width: 736px), (max-width: 736px) {
  .rootWrapper.create .mainContent {
    height: calc(calc(var(--vh, 1vh) * 100) - 60px);
  }
}
.rootWrapper.create .pageLabel {
  opacity: 0;
}
.rootWrapper.create.pageScrolled .mainNavBar {
  background-color: #ffffff !important;
  border-bottom-color: #e0e0e0;
}
@media only screen and (max-device-width: 736px), (max-width: 736px) {
  .rootWrapper.create {
    padding-top: 60px;
  }
}
.rootWrapper.user-collections.theme-1 .profileContainer .profile,
.rootWrapper.user-collections.theme-2 .profileContainer .profile {
  height: 0 !important;
  padding: 0 !important;
}
.rootWrapper.user-collections.theme-1 .profileContainer .profile .profile-image,
.rootWrapper.user-collections.theme-2 .profileContainer .profile .profile-image {
  z-index: 10;
  width: 3rem;
  height: 3rem;
  top: auto;
  bottom: -8rem;
  left: 2rem;
  transform: none;
}
.rootWrapper.user-collections.theme-1 .profileContainer .profile .profile-name,
.rootWrapper.user-collections.theme-2 .profileContainer .profile .profile-name {
  z-index: 10;
  bottom: -8rem;
  left: 5.25rem;
  right: auto;
  transform: none;
  padding-left: 1rem;
  padding-right: 2.5rem;
}
.rootWrapper.user-collections.theme-1 .profileContainer .profile .profile-name .name,
.rootWrapper.user-collections.theme-2 .profileContainer .profile .profile-name .name {
  font-size: 2rem;
  line-height: 1.6;
  color: var(--sfProfile_titleTextColor);
}
.rootWrapper.user-collections.theme-1 .profileContainer .profile .profile-name .badge,
.rootWrapper.user-collections.theme-2 .profileContainer .profile .profile-name .badge {
  width: 1rem;
  height: 1rem;
  margin-left: 0rem;
  right: 0.5rem;
  top: 0.5rem;
}
.rootWrapper.user-collections.theme-1 .profileContainer .profile .profile-name .badge path,
.rootWrapper.user-collections.theme-2 .profileContainer .profile .profile-name .badge path {
  fill: var(--sfProfile_titleTextColor);
}
.rootWrapper.user-collections.theme-1 .profileContainer .profile .profile-social,
.rootWrapper.user-collections.theme-2 .profileContainer .profile .profile-social {
  z-index: 10;
  bottom: -11rem;
  left: 6rem;
  transform: scale(0.8);
  transform-origin: left center;
  padding: 0.5rem;
}
.rootWrapper.user-collections.theme-1 .profileContainer .profile .profile-social .link,
.rootWrapper.user-collections.theme-2 .profileContainer .profile .profile-social .link {
  color: var(--sfProfile_titleTextColor);
}
.rootWrapper.user-collections.theme-1 .profileContainer .profile .profile-social .link .icon,
.rootWrapper.user-collections.theme-2 .profileContainer .profile .profile-social .link .icon {
  color: var(--sfProfile_titleTextColor);
}
.rootWrapper.user-collections.theme-1 .profileContainer .profile .profile-cover,
.rootWrapper.user-collections.theme-2 .profileContainer .profile .profile-cover {
  display: none !important;
}
.rootWrapper.user-collections.theme-1 .profileContainer .toolbar .toolbar-wrapper,
.rootWrapper.user-collections.theme-2 .profileContainer .toolbar .toolbar-wrapper {
  margin-left: 0;
  width: 100%;
}
.rootWrapper.user-collections.theme-1 [class*='content--collections-view'],
.rootWrapper.user-collections.theme-2 [class*='content--collections-view'] {
  padding: 0;
}
.ui.modals {
  padding: 1rem !important;
  z-index: 2000 !important;
}
@media only screen and (max-device-width: 736px), (max-width: 736px) {
  .ui.modals {
    padding: 0 !important;
  }
}
.ui.modals > .actions {
  height: 60px;
}
.ui.modals .loginModal {
  width: 100% !important;
  max-width: 320px;
  border-radius: 1rem;
  overflow: hidden;
}
.ui.modals .sfFullscreen {
  width: calc(calc(var(--vw, 1vw) * 100) - 4rem) !important;
  height: calc(calc(var(--vh, 1vh) * 100) - 4rem) !important;
  border-radius: 0 !important;
}
@media only screen and (max-device-width: 736px), (max-width: 736px) {
  .ui.modals .sfFullscreen {
    width: calc(var(--vw, 1vw) * 100) !important;
    height: calc(var(--vh, 1vh) * 100) !important;
    margin: 0 !important;
  }
}
.ui.modals .sfFullscreen > .content {
  position: relative;
  background-color: #fff;
  padding: 0 !important;
  overflow: hidden !important;
  border-radius: 0 !important;
  min-height: calc(calc(var(--vh, 1vh) * 100) - 4rem - 60px) !important;
  max-height: calc(calc(var(--vh, 1vh) * 100) - 4rem - 60px) !important;
  margin: 0 !important;
}
@media only screen and (max-device-width: 736px), (max-width: 736px) {
  .ui.modals .sfFullscreen > .content {
    padding: 0 !important;
    min-height: calc(calc(var(--vh, 1vh) * 100) - 60px) !important;
    max-height: calc(calc(var(--vh, 1vh) * 100) - 60px) !important;
  }
}
.ui.modals .sfFullscreen > .actions {
  height: 60px;
}
.ui.modals .collectionsFormModal {
  height: calc(calc(var(--vh, 1vh) * 60) - 4rem);
  transition: width 0.3s ease-out, height 0.3s ease-out;
}
.ui.modals .collectionsFormModal > .content {
  height: 100%;
  position: relative;
  background-color: #fff;
  padding: 0 !important;
  overflow: hidden !important;
  border-radius: 0 !important;
  margin: 0 !important;
}
@media only screen and (max-device-width: 736px), (max-width: 736px) {
  .ui.modals .collectionsFormModal > .content {
    padding: 0 !important;
  }
}
.ui.modals .collectionsSelectionModal .header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ui.modals .collectionsSelectionModal .header .title {
  flex-grow: 1;
}
.ui.modals .collectionsSelectionModal .header .header-extra {
  font-size: 1rem;
  font-weight: normal;
  text-align: right;
}
.ui.modals .collectionsSelectionModal .header .header-extra .label {
  font-weight: bold;
}
.ui.modals .collectionsSelectionModal > .content {
  position: relative;
  background-color: #fff;
  padding: 0 !important;
  overflow: hidden !important;
  border-radius: 0 !important;
  min-height: calc(calc(var(--vh, 1vh) * 100) - 3.5rem - 120px) !important;
  max-height: calc(calc(var(--vh, 1vh) * 100) - 3.5rem - 120px) !important;
  margin: 0 !important;
}
@media only screen and (max-device-width: 736px), (max-width: 736px) {
  .ui.modals .collectionsSelectionModal > .content {
    padding: 0 !important;
    min-height: calc(calc(var(--vh, 1vh) * 100) - 120px) !important;
    max-height: calc(calc(var(--vh, 1vh) * 100) - 120px) !important;
  }
}
.ui.modals .dropzonModal .segment.dropzone:hover {
  pointer-events: all;
  border-color: #9bc53d !important;
}
.ui.modals .dropzonModal .segment.dropzone.isDragActive {
  border-color: #9bc53d !important;
  background-color: rgba(155, 197, 61, 0.2) !important;
}
.ui.modals .dropzonModal .segment.dropzone.isDragActive .uploaderButton {
  display: none !important;
  pointer-events: none;
}
.ui.modals .dropzonModal .segment.dropzone .header {
  width: 100%;
}
.ui.modals .dropzonModal .segment.dropzone .uploaderButton {
  pointer-events: all;
}
.ui.modals .dropzonModal .segment.dropzone .dropzoneWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
}
.ui.modals .dropzonModal > .header {
  position: relative;
}
.ui.modals .dropzonModal > .header .dropzoneProgress {
  position: absolute;
  margin: 0 !important;
  bottom: 0;
  left: 0;
  width: 100%;
}
.ui.modals .dropzonModal .dropzoneActionMessage {
  width: auto;
  float: left;
  padding-top: 0.5rem;
}
.ui.modals .dropzonModal .thumbnailsAction {
  margin-top: 2rem;
  background-color: #F9FAFB;
  border-top: 1px solid #e9e9e9;
  width: 100%;
  text-align: center;
  padding: 1rem;
  position: sticky;
  bottom: -2rem;
  right: -2rem;
  left: -2rem;
  z-index: 2;
  pointer-events: all;
}
.ui.modals .dropzonModal .thumbnailsAction:before {
  content: '';
  display: block;
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 10px;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
  pointer-events: none;
}
.ui.modals .coverPhotoModal .content {
  padding: 0 !important;
}
.ui.modals .coverPhotoModal .content .ui.tab {
  border: none !important;
  max-width: 100% !important;
}
@media only screen and (max-device-width: 736px), (max-width: 736px) {
  .ui.modals .coverPhotoModal .mediaTab > .ui.grid {
    display: block !important;
  }
  .ui.modals .coverPhotoModal .mediaTab .four.wide.column {
    width: 100% !important;
  }
  .ui.modals .coverPhotoModal .mediaTab .twelve.wide.column {
    width: 100% !important;
  }
}
.ui.modals .drawModal {
  width: auto;
}
@media only screen and (max-device-width: 736px), (max-width: 736px) {
  .ui.modals .drawModal {
    height: 100%;
    position: relative;
    margin: 0 !important;
  }
}
@media only screen and (max-device-width: 736px), (max-width: 736px) {
  .ui.modals .drawModal {
    width: 100%;
    height: 100%;
  }
  .ui.modals .drawModal .content {
    height: 100%;
    max-height: calc(calc(var(--vh, 1vh) * 100) - 56px);
  }
}
@media only screen and (max-device-width: 736px), (max-width: 736px) {
  .ui.modals .selfPrintModal {
    width: 100%;
    height: 100%;
    margin: 0 !important;
  }
  .ui.modals .selfPrintModal .content {
    height: 100%;
    max-height: calc(calc(var(--vh, 1vh) * 100) - 56px - 4rem) !important;
  }
}
.rootWrapper.dash .mainNavBar .sideBarToggle {
  margin-left: -1.2rem;
}
.rootWrapper.dash .mainNavBar .titlebar {
  width: 220px;
}
.rootWrapper.dash .mainNavBar .titlebar .header {
  margin: 0;
  padding: 0 !important;
}
@media only screen and (max-device-width: 1024px), (max-width: 1024px) {
  .rootWrapper.dash .mainNavBar .titlebar {
    width: auto;
  }
}
.rootWrapper.dash .pageLabel {
  opacity: 0;
}
.rootWrapper.dash .mainContent {
  background-color: #F4F7F6;
}
.rootWrapper[class*='theme-'] .mainNavBar {
  background-color: var(--sfProfile_bgColor);
  border-bottom-color: var(--sfProfile_dividerColor);
}
.rootWrapper[class*='theme-'] .mainNavBar .pageLabel {
  color: var(--sfProfile_titleTextColor);
}
.rootWrapper[class*='theme-'] .mainNavBar .menuWrapper > .menu > .item.storeButtonsGroup .ui.dropdown {
  background-color: var(--sfProfile_bgColor);
  color: var(--sfProfile_linkTextColor);
  border: 1px solid var(--sfProfile_dividerColor);
}
.rootWrapper[class*='theme-'] .mainNavBar .menuWrapper > .menu > .item.storeButtonsGroup .ui.dropdown.active {
  color: var(--sfProfile_linkTextColorActive);
}
.rootWrapper[class*='theme-'] .mainNavBar .menuWrapper > .menu > .item.storeButtonsGroup .ui.dropdown.active .text {
  color: var(--sfProfile_linkTextColorActive);
}
.rootWrapper[class*='theme-'] .mainNavBar .menuWrapper > .menu > .item.storeButtonsGroup .ui.dropdown .menu {
  background-color: var(--sfProfile_bgColor) !important;
  border-color: var(--sfProfile_dividerColor);
}
.rootWrapper[class*='theme-'] .mainNavBar .menuWrapper > .menu > .item.storeButtonsGroup .ui.dropdown .menu .item {
  border-color: var(--sfProfile_dividerColor);
}
.rootWrapper[class*='theme-'] .mainNavBar .menuWrapper > .menu > .item.storeButtonsGroup .ui.dropdown .menu .item .text {
  color: var(--sfProfile_linkTextColor) !important;
}
.rootWrapper[class*='theme-'] .mainNavBar .menuWrapper > .menu > .item.storeButtonsGroup .ui.dropdown .menu .item.selected,
.rootWrapper[class*='theme-'] .mainNavBar .menuWrapper > .menu > .item.storeButtonsGroup .ui.dropdown .menu .item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.rootWrapper[class*='theme-'] .mainNavBar .menuWrapper > .menu > .item.storeButtonsGroup .ui.dropdown .menu .item.selected .text,
.rootWrapper[class*='theme-'] .mainNavBar .menuWrapper > .menu > .item.storeButtonsGroup .ui.dropdown .menu .item:hover .text {
  color: var(--sfProfile_linkTextColorActive) !important;
}
.rootWrapper[class*='theme-'] .mainNavBar .menuWrapper > .menu > .item .storeButtons {
  color: var(--sfProfile_linkTextColor) !important;
}
.rootWrapper[class*='theme-'] .mainNavBar .menuWrapper > .menu > .item .storeButtons .icon {
  color: var(--sfProfile_linkTextColor) !important;
}
.rootWrapper[class*='theme-'] .mainNavBar .menuWrapper > .menu > .item .storeButtons:hover {
  color: var(--sfProfile_linkTextColorHover) !important;
}
.rootWrapper[class*='theme-'] .mainNavBar .menuWrapper > .menu > .item .storeButtons:hover .icon {
  color: var(--sfProfile_linkTextColorHover) !important;
}
.rootWrapper[class*='theme-'] .mainNavBar .menuWrapper > .menu > .item .storeButtons.active {
  color: var(--sfProfile_linkTextColorActive) !important;
}
.rootWrapper[class*='theme-'] .mainNavBar .menuWrapper > .menu > .item .storeButtons.active .icon {
  color: var(--sfProfile_linkTextColorActive) !important;
}
.rootWrapper[class*='theme-'] .mainNavBar .menuWrapper .cartMenu > .trigger {
  color: var(--sfProfile_linkTextColor) !important;
}
.rootWrapper[class*='theme-'] .mainNavBar .menuDropdown .trigger .triggerName {
  color: var(--sfProfile_linkTextColor) !important;
}
.rootWrapper[class*='theme-'] .mainNavBar .menuDropdown .trigger .avatar {
  background-color: var(--sfProfile_dividerColor);
}
.rootWrapper[class*='theme-'] .mainContent::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}
.rootWrapper[class*='theme-'] .mainContent::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  /* background: ${stylevars.typography.subtitle.color}; */
  background: rgba(112, 112, 112, 0.25);
  transition: color 0.2s ease;
}
.rootWrapper[class*='theme-'] .mainContent::-webkit-scrollbar-track {
  /* background: ${stylevars.paper.borderColor}; */
  background: var(--sfProfile_bgColor);
  border-radius: 0px;
}
.rootWrapper[class*='theme-'] .mainContent:hover::-webkit-scrollbar-thumb {
  /* background: ${stylevars.palette.primary.main}; */
  background: var(--sfProfile_socialIconColor);
}
.rootWrapper .mainNavBar .ui.menu .ui.dropdown .menu {
  margin-top: 0;
  background-color: var(--sfProfile_bgColor) !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.rootWrapper .mainNavBar .ui.menu .ui.dropdown .menu:after {
  background-color: var(--sfProfile_bgColor) !important;
}
.rootWrapper .mainNavBar .ui.menu .ui.dropdown .menu > .header h4,
.rootWrapper .mainNavBar .ui.menu .ui.dropdown .menu > .header p {
  color: var(--sfProfile_profileTextColor) !important;
}
.rootWrapper .mainNavBar .ui.menu .ui.dropdown .menu > .header .header {
  color: var(--sfProfile_profileTextColor) !important;
}
.rootWrapper .mainNavBar .ui.menu .ui.dropdown .menu > .divider {
  border-top-color: var(--sfProfile_dividerColor) !important;
}
.rootWrapper .mainNavBar .ui.menu .ui.dropdown .menu > .item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--sfProfile_profileTextColor) !important;
}
.rootWrapper .mainNavBar .ui.menu .ui.dropdown .menu > .item .icon {
  color: var(--sfProfile_socialIconColor) !important;
}
.rootWrapper .mainNavBar .ui.menu .ui.dropdown .menu > .item .icon:not(.dropdown) {
  font-size: 1.5rem !important;
  line-height: 1;
}
.rootWrapper .mainNavBar .ui.menu .ui.dropdown .menu > .item > span {
  line-height: 1;
  margin: 0;
}
.rootWrapper.theme-1.pageScrolled .profileContainer.theme-1 .profile-image {
  left: calc(var(--vw, 1vw) * -20);
}
@media only screen and (max-device-width: 736px), (max-width: 736px) {
  .rootWrapper.theme-1.pageScrolled .profileContainer.theme-1 .profile-image {
    left: 50%;
  }
}
.rootWrapper.theme-1.pageScrolled .profileContainer.theme-1 .profile-name {
  left: calc(calc(var(--vh, 1vh) * -20) - 3.5rem);
}
@media only screen and (max-device-width: 736px), (max-width: 736px) {
  .rootWrapper.theme-1.pageScrolled .profileContainer.theme-1 .profile-name {
    left: 0;
  }
}
.rootWrapper.theme-1.pageScrolled .profileContainer.theme-1 .toolbar-wrapper {
  margin-left: 0rem;
  width: calc(100%);
}
.rootWrapper.theme-2 .profileContainer.theme-2 .profile-image {
  bottom: auto;
  top: calc(50% - 2rem);
  left: 50%;
  transform: translate(-50%, -50%);
}
.rootWrapper.theme-2 .profileContainer.theme-2 .profile-name {
  opacity: 1;
  left: 50%;
  transform: translateX(-50%);
  padding-right: 2rem;
  padding-left: 2rem;
}
.rootWrapper.theme-2 .profileContainer.theme-2 .profile-social {
  left: 50%;
  transform: translateX(-50%);
}
.rootWrapper.theme-2 .profileContainer.theme-2 .toolbar-wrapper {
  display: flex;
  justify-content: center;
  margin-left: 0;
  width: calc(100%);
}
.rootWrapper.theme-2.pageScrolled .profileContainer .profile-image {
  top: calc(var(--vw, 1vw) * -20);
}
.rootWrapper.theme-2.pageScrolled .profileContainer .profile-name {
  opacity: 0;
}
.rootWrapper.theme-2.pageScrolled .profileContainer .toolbar-wrapper {
  margin-left: 0rem;
  width: calc(100%);
}
.rootWrapper.theme-3 .profileContainer.theme-3 {
  display: grid;
  grid-template-columns: [profile] 400px [toolbar] 6fr;
  grid-template-rows: [toolbar-row] 41px [content] 6fr [footer] 0;
}
.rootWrapper.theme-3 .profileContainer.theme-3 .profile {
  grid-column-start: profile;
  grid-column-end: span toolbar;
  grid-row-start: toolbar-row;
  grid-row-end: span footer;
  width: 400px;
  height: calc(calc(var(--vh, 1vh) * 100) - 60px);
  top: 60px;
  position: fixed;
}
.rootWrapper.theme-3 .profileContainer.theme-3 .toolbar {
  grid-column-start: toolbar;
  grid-column-end: span toolbar;
  grid-row-start: toolbar-row;
  grid-row-end: span content;
}
.rootWrapper.theme-3 .profileContainer.theme-3 .content {
  grid-column-start: toolbar;
  grid-column-end: span toolbar;
  grid-row-start: content;
  grid-row-end: span footer;
}
.rootWrapper.theme-3 .profileContainer.theme-3 .profile-cover {
  width: 100%;
  height: 100%;
}
.rootWrapper.theme-3 .profileContainer.theme-3 .profile-image {
  bottom: auto;
  top: calc(50% - 10rem);
  left: 50%;
  transform: translate(-50%, -50%);
}
.rootWrapper.theme-3 .profileContainer.theme-3 .profile-name {
  opacity: 1;
  left: 50%;
  bottom: auto;
  top: calc(50%);
  transform: translate(-50%, -50%);
  padding-right: 2rem;
  padding-left: 2rem;
}
.rootWrapper.theme-3 .profileContainer.theme-3 .profile-social {
  left: 50%;
  bottom: auto;
  top: calc(50% + 2rem);
  transform: translate(-50%, -50%);
}
.rootWrapper.theme-3 .profileContainer.theme-3 .toolbar-wrapper {
  margin-left: 0;
  width: calc(100%);
  padding: 0 2rem;
}
.rootWrapper.theme-3 .profileContainer.theme-3 > .content {
  min-height: calc(calc(var(--vh, 1vh) * 100) - 60px - 3rem);
}
.rootWrapper.theme-3 .profileContainer.theme-3 > .content .collectionViewer .cover {
  left: 400px;
}
@media only screen and (min-device-width: 240px) and (max-device-width: 736px), (min-width: 240px) and (max-width: 812px) {
  .rootWrapper.theme-1 .profileContainer,
  .rootWrapper.theme-2 .profileContainer,
  .rootWrapper.theme-3 .profileContainer {
    display: block !important;
  }
}
@media only screen and (min-device-width: 240px) and (max-device-width: 736px), (min-width: 240px) and (max-width: 812px) {
  .rootWrapper.theme-1 .profileContainer .profile,
  .rootWrapper.theme-2 .profileContainer .profile,
  .rootWrapper.theme-3 .profileContainer .profile {
    top: auto !important;
    width: 100% !important;
    height: 220px !important;
    position: relative !important;
  }
}
@media only screen and (min-device-width: 240px) and (max-device-width: 736px), (min-width: 240px) and (max-width: 812px) {
  .rootWrapper.theme-1 .profileContainer .profile-cover,
  .rootWrapper.theme-2 .profileContainer .profile-cover,
  .rootWrapper.theme-3 .profileContainer .profile-cover {
    height: 220px !important;
  }
}
@media only screen and (min-device-width: 240px) and (max-device-width: 736px), (min-width: 240px) and (max-width: 812px) {
  .rootWrapper.theme-1 .profileContainer .profile-image,
  .rootWrapper.theme-2 .profileContainer .profile-image,
  .rootWrapper.theme-3 .profileContainer .profile-image {
    top: calc(50% - 2rem) !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}
@media only screen and (min-device-width: 240px) and (max-device-width: 736px), (min-width: 240px) and (max-width: 812px) {
  .rootWrapper.theme-1 .profileContainer .profile-name,
  .rootWrapper.theme-2 .profileContainer .profile-name,
  .rootWrapper.theme-3 .profileContainer .profile-name {
    top: auto !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    bottom: 3rem !important;
  }
}
@media only screen and (min-device-width: 240px) and (max-device-width: 736px), (min-width: 240px) and (max-width: 812px) {
  .rootWrapper.theme-1 .profileContainer .profile-social,
  .rootWrapper.theme-2 .profileContainer .profile-social,
  .rootWrapper.theme-3 .profileContainer .profile-social {
    top: auto !important;
    bottom: 0rem !important;
  }
}
@media only screen and (min-device-width: 240px) and (max-device-width: 736px), (min-width: 240px) and (max-width: 812px) {
  .rootWrapper.theme-1 .profileContainer .toolbar-wrapper,
  .rootWrapper.theme-2 .profileContainer .toolbar-wrapper,
  .rootWrapper.theme-3 .profileContainer .toolbar-wrapper {
    display: flex;
    justify-content: center;
    margin-left: 0 !important;
    width: calc(100%) !important;
    padding: 0 2rem !important;
  }
}
.searchModal {
  overflow: hidden;
  border-radius: 1rem !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px !important;
  overflow: visible !important;
}
.searchModal .content {
  background-color: transparent !important;
  overflow: visible !important;
}
@media only screen and (min-device-width: 240px) and (max-device-width: 736px), (min-width: 240px) and (max-width: 812px) {
  .searchModal {
    height: calc(calc(var(--vh, 1vh) * 100) - 2rem);
  }
}
