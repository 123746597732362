@navBarHeight: 60px;
@sideBarWidth: 400px;
@color1: var(--sfProfile_bgColor);

body {
  height: calc(var(--vh, 1vh) * 100) !important;
  overflow: hidden;
}
html {
  height: calc(var(--vh, 1vh) * 100) !important;
}
body {
  #root {
    overflow-x: hidden;
    overflow-y: hidden;
    height: calc(calc(var(--vh, 1vh) * 100) - 0px);
  }

  &.no-scrolling {
    .mainContent {
      overflow-y: hidden !important;
      overflow-x: hidden !important;
    }
  }
}
.couponStatusLabel {
  display: inline-block;
  min-height: 21px;
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.couponStatusCol {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  flex-direction: column;
}

.text--light {
  font-weight: lighter;
}

.text--bold {
  font-weight: bold;
}

.text--italic {
  font-style: italic;
}

.text--outlined {
  position: relative;
  white-space: nowrap;
  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: inherit;
  }
}

.ui.divider.clearBottom {
  margin-bottom: 0;
}

// QUILL EDITOR
.ql-editor p, .ql-editor ol, .ql-editor ul, .ql-editor pre, .ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6 {
  margin-top: 1em;
  margin-bottom: 1em;
}

.expirationDateField  > .field {
    margin-bottom: 0 !important;
}
.expirationDateField  > .field > .field {
  margin-bottom: 0 !important;
}
.expirationDateField  > .field > .field > .field {
  margin-bottom: 0 !important;
}

// GENERAL DROPDOWN
.ui.dropdown > .menu {
  background-color: #ffffff !important;
}

.ui.menu .ui.dropdown .menu > .item.dropdownItemDivider {
  padding: 0 !important;
}



.payoutSettingsForm {
  position: relative;
  .field:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

// CUSTOM UI INPUT FIELD ICON
.ui.input {
  input:focus {
    + .customicon {
      > svg {
        * {
          fill: rgba(0,0,0,0.75);
        }
      }
    }
  }
  .customicon {
    width: 3rem;
    height: 100%;
    position: absolute;
    left: 0;
    padding: 0 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    > svg {
      width: 100%;
      height: 100%;
      * {
        transition: all 0.3s ease-out;
        fill: rgba(0,0,0,0.5);
      }
    }
  }
}

.rootWrapper {
  margin-top: 60px;
  &.checkout, &.store {
    .sideBarToggle {
      display: none;
    }
    .titlebar {
      display: block;
    }
  }
  &.pageScrolled {
    .mainNavBar {
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
      .pageLabel span {
        transform: translateY(0);
      }
    }
  }
  &.splash {
    margin-top: 0;
    .mainContent {
      height: calc(calc(var(--vh, 1vh) * 100));
      overflow: hidden;
    }
  }
  &.create {
    margin-top: 0;
    .mainNavBar {
      background-color: rgba(255, 255, 255, 0) !important;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-bottom-color: rgba(224, 224, 224, 0);
      border-bottom: none;
      transition: background-color 0.5s ease-out, border 0.5s ease-out;
    }
    .mainContent {
      height: calc(calc(var(--vh, 1vh) * 100));

      @media only screen and (max-device-width: 736px), (max-width: 736px) {
        height: calc(calc(var(--vh, 1vh) * 100) - 60px);
      }
    }
    .pageLabel {
      opacity: 0;
    }
    &.pageScrolled {
      .mainNavBar {
        background-color: rgba(255, 255, 255, 1) !important;
        border-bottom-color: rgba(224, 224, 224, 1);
      }
    }
    @media only screen and (max-device-width: 736px), (max-width: 736px) {
      padding-top: 60px;
    }
  }
  &.user-collections {
    &.theme-1, &.theme-2 {
      .profileContainer {
        .profile {
          height: 0 !important;
          padding: 0 !important;
          .profile-image {
            z-index: 10;
            width: 3rem;
            height: 3rem;
            top: auto;
            bottom: -8rem;
            left: 2rem;
            transform: none;
          }
          .profile-name {
            z-index: 10;
            bottom: -8rem;
            left: 5.25rem;
            right: auto;
            transform: none;
            padding-left: 1rem;
            padding-right: 2.5rem;
            // background-color: var(--sfProfile_profileBGColor);
            .name {
              font-size: 2rem;
              line-height: 1.6;
              color: var(--sfProfile_titleTextColor);
            }
            .badge {
              width: 1rem;
              height: 1rem;
              margin-left: 0rem;
              right: 0.5rem;
              top: 0.5rem;
              path {
                fill: var(--sfProfile_titleTextColor);
              }
            }
          }
          .profile-social {
            z-index: 10;
            bottom: -11rem;
            left: 6rem;
            transform: scale(0.8);
            transform-origin: left center;
            // background-color: var(--sfProfile_profileBGColor);
            padding: 0.5rem;

            .link {
              color: var(--sfProfile_titleTextColor);
              .icon {
                color: var(--sfProfile_titleTextColor);
              }
            }
          }
          .profile-cover {
            display: none !important;
          }
        }
        .toolbar {
          .toolbar-wrapper {
            margin-left: 0;
            width: 100%;
          }
        }
      }
      [class*='content--collections-view'] {
        padding: 0;
      }
    }
  }
}


// ==============================================
// ==============================================
// CUSTOM MODALS 
// ==============================================
// ==============================================
.ui.modals {
  padding: 1rem !important;
  z-index: 2000 !important;
  @media only screen and (max-device-width: 736px), (max-width: 736px) {
    padding: 0 !important;
  }
  
  > .actions {
    height: 60px;
  }

  // LOGIN MODAL
  .loginModal {
    width: 100% !important;
    max-width: 320px;
    border-radius: 1rem;
    overflow: hidden;
  }

  // FULLSCREEN MODALS
  .sfFullscreen {
    width: calc(calc(var(--vw, 1vw) * 100) - 4rem) !important;
    height: calc(calc(var(--vh, 1vh) * 100) - 4rem) !important;
    border-radius: 0 !important;
    @media only screen and (max-device-width: 736px), (max-width: 736px) {
      width: calc(var(--vw, 1vw) * 100) !important;
      height: calc(var(--vh, 1vh) * 100) !important;
      margin: 0 !important;
    }
    > .content {
      position: relative;
      background-color: #fff;
      padding: 0 !important;
      overflow: hidden !important;
      border-radius: 0 !important;
      min-height: calc(calc(var(--vh, 1vh) * 100) - 4rem - 60px) !important;
      max-height: calc(calc(var(--vh, 1vh) * 100) - 4rem - 60px) !important;
      margin: 0 !important;
      @media only screen and (max-device-width: 736px), (max-width: 736px) {
        padding: 0 !important;
        min-height: calc(calc(var(--vh, 1vh) * 100) - 60px) !important;
        max-height: calc(calc(var(--vh, 1vh) * 100) - 60px) !important;
      }
    }
    > .actions {
      height: 60px;
    }
  }
  
  .collectionsFormModal {
    height: calc(calc(var(--vh, 1vh) * 60) - 4rem);
    // max-height: calc(calc(var(--vh, 1vh) * 60) - 4rem) !important;
    transition: width 0.3s ease-out, height 0.3s ease-out;
    > .content {
      height: 100%;
      position: relative;
      background-color: #fff;
      padding: 0 !important;
      overflow: hidden !important;
      border-radius: 0 !important;
      // min-height: 400px;
      // max-height: calc(calc(var(--vh, 1vh) * 100) - 4rem - 60px) !important;
      margin: 0 !important;
      @media only screen and (max-device-width: 736px), (max-width: 736px) {
        padding: 0 !important;
        // min-height: 400px;
        // max-height: calc(calc(var(--vh, 1vh) * 100) - 60px) !important;
      }
    }
  }

  .collectionsSelectionModal {
    .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .title {
        flex-grow: 1;
      }
      .header-extra {
        font-size: 1rem;
        font-weight: normal;
        text-align: right;
        .label {
          font-weight: bold;
        }
      }
    }
    > .content {
      position: relative;
      background-color: #fff;
      padding: 0 !important;
      overflow: hidden !important;
      border-radius: 0 !important;
      min-height: calc(calc(var(--vh, 1vh) * 100) - 3.5rem - 120px) !important;
      max-height: calc(calc(var(--vh, 1vh) * 100) - 3.5rem - 120px) !important;
      margin: 0 !important;
      @media only screen and (max-device-width: 736px), (max-width: 736px) {
        padding: 0 !important;
        min-height: calc(calc(var(--vh, 1vh) * 100) - 120px) !important;
        max-height: calc(calc(var(--vh, 1vh) * 100) - 120px) !important;
      }
    }
  }

  // UPLOADER DROPZONE
  .dropzonModal {
    .segment.dropzone {
      &:hover {
        pointer-events: all;
        border-color: #9bc53d !important;
      }
      &.isDragActive {
        border-color: #9bc53d !important;
        background-color: rgba(155,197,61, 0.2) !important;
        .uploaderButton {
          display: none !important;
          pointer-events: none;
        }
      }
  
      .header {
        width: 100%;
      }
  
      .uploaderButton {
        pointer-events: all;
      }
  
      .dropzoneWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        pointer-events: none;
      }
    }
  
    > .header {
      position: relative;
  
      .dropzoneProgress {
        position: absolute;
        margin: 0 !important;
        bottom: 0;
        left: 0;
        width: 100%;
        // .bar {
        //   height: 2px !important;
        // }
      }
    }
    .dropzoneActionMessage {
      width: auto;
      float: left;
      padding-top: 0.5rem;
    }
  
    .thumbnailsAction {
      margin-top: 2rem;
      background-color: #F9FAFB;
      border-top: 1px solid #e9e9e9;
      width: 100%;
      text-align: center;
      padding: 1rem;
      position: sticky;
      bottom: -2rem;
      right: -2rem;
      left: -2rem;
      z-index: 2;
      pointer-events: all;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -10px;
        left: 0;
        width: 100%;
        height: 10px;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
        pointer-events: none;
      }
    }
  }

  // COVER PHOTO MODAL
  .coverPhotoModal {
    .content {
      padding: 0 !important;
      .ui.tab {
        border: none !important;
        max-width: 100% !important;
      }
    }
    @media only screen and (max-device-width: 736px), (max-width: 736px) {
      .mediaTab {
        > .ui.grid {
          display: block !important;
        }

        .four.wide.column {
          width: 100% !important;
        }
        .twelve.wide.column {
          width: 100% !important;
        }
      }
    }
  }

  // DRAW MODAL
  .drawModal {
    @media only screen and (max-device-width: 736px), (max-width: 736px) {
      height: 100%;
      position: relative;
      margin: 0 !important;
    }
    width: auto;
    @media only screen and (max-device-width: 736px), (max-width: 736px) {
      width: 100%;
      height: 100%;

      .content {
        height: 100%;
        max-height: calc(calc(var(--vh, 1vh) * 100) - 56px);
      }
    }
  }

  // SELF PRINT MODAL
  .selfPrintModal {
    @media only screen and (max-device-width: 736px), (max-width: 736px) {
      width: 100%;
      height: 100%;
      margin: 0 !important;

      .content {
        height: 100%;
        max-height: calc(calc(var(--vh, 1vh) * 100) - 56px - 4rem) !important;
      }
    }
  }
}

// ROOT WRAPPER LAYOUT
.rootWrapper {
  &.dash {
    .mainNavBar {
      .sideBarToggle {
        margin-left: -1.2rem;
      }
      .titlebar {
        width: 220px;
        .header {
          margin: 0;
          padding: 0 !important;
        }
        @media only screen and (max-device-width:1024px), (max-width: 1024px) {
          width: auto;
        }
      }
    }
    .pageLabel {
      opacity: 0;
    }
    // &.gallery {
    //   .mainContent {
    //     margin-top: calc(60px + 60px);
    //     height: calc(calc(var(--vh,1vh) * 100) - 120px)
    //   }
    // }
    .mainContent {
      background-color: #F4F7F6;
    }
  }
  &[class*='theme-']  {
    .mainNavBar {
      background-color: var(--sfProfile_bgColor);
      border-bottom-color: var(--sfProfile_dividerColor);
      .pageLabel {
        color: var(--sfProfile_titleTextColor);
      }
      .menuWrapper {
        > .menu {
          > .item {
            &.storeButtonsGroup {
              // &.divided {
              //   border-right: 1px solid var(--sfProfile_dividerColor) !important;
              // }
              .ui.dropdown {
                background-color: var(--sfProfile_bgColor);
                color: var(--sfProfile_linkTextColor);
                border: 1px solid var(--sfProfile_dividerColor);
                &.active {
                  color: var(--sfProfile_linkTextColorActive);
                  .text {
                    color: var(--sfProfile_linkTextColorActive);
                  }
                }
                .menu {
                  background-color: var(--sfProfile_bgColor) !important;
                  border-color: var(--sfProfile_dividerColor);
                  .item {
                    border-color: var(--sfProfile_dividerColor);
                    .text {
                      color: var(--sfProfile_linkTextColor) !important;
                    }
                    &.selected, &:hover {
                      background-color: rgba(0,0,0,0.1);
                      .text {
                        color: var(--sfProfile_linkTextColorActive) !important;
                      }
                    }
                  }
                }
              }
            }
            // &.menuItemProfile {
            //   // .menu {
            //   //   > .item {
            //   //     .icon {
            //   //       &:not(.dropdown) {
            //   //         font-size: 2rem !important;
            //   //       }
            //   //     }
            //   //   }
            //   // }
            // }
            .storeButtons {
              color: var(--sfProfile_linkTextColor) !important;
              // border-bottom: 2px solid transparent !important;
              .icon {
                color: var(--sfProfile_linkTextColor) !important;
              }
              &:hover {
                color: var(--sfProfile_linkTextColorHover) !important;
                .icon {
                  color: var(--sfProfile_linkTextColorHover) !important;
                }
                // border-bottom: 2px solid var(--sfProfile_linkLineColorHover) !important;
              }
              &.active {
                color: var(--sfProfile_linkTextColorActive) !important;
                .icon {
                  color: var(--sfProfile_linkTextColorActive) !important;
                }
                // border-bottom: 2px solid var(--sfProfile_linkLineColorActive) !important;
              }
            }
          }
        }
        .cartMenu {
          > .trigger {
            color: var(--sfProfile_linkTextColor) !important;
            // &:not(:last-of-type) {
            //   border-right: 1px solid var(--sfProfile_dividerColor) !important;
            // }
          }
        }
      }
      .menuDropdown {
        .trigger {
          .triggerName {
            color: var(--sfProfile_linkTextColor) !important;
          }
          .avatar {
            background-color: var(--sfProfile_dividerColor);
          }
        }
      }
    }

    // SCROLLBARS
    .mainContent {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
        height: 10px;
      }
      &::-webkit-scrollbar-thumb {
        cursor: pointer;
        border-radius: 5px;
        /* background: ${stylevars.typography.subtitle.color}; */
        // background: var(--sfProfile_dividerColor);
        background: rgba(112,112,112,0.25);
        transition: color 0.2s ease;
      }
      &::-webkit-scrollbar-track {
        /* background: ${stylevars.paper.borderColor}; */
        background: var(--sfProfile_bgColor);
        border-radius: 0px;
      }
      
      &:hover {
        &::-webkit-scrollbar-thumb {
          /* background: ${stylevars.palette.primary.main}; */
          // background: var(--sfProfile_linkTextColorActive);
          background: var(--sfProfile_socialIconColor);
        }
      }
    }
  }

  .mainNavBar {
    .ui.menu .ui.dropdown .menu {
      margin-top: 0;
      background-color: var(--sfProfile_bgColor) !important;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
      &:after {
        background-color: var(--sfProfile_bgColor) !important;
      }
      > .header {
        h4, p {
          color: var(--sfProfile_profileTextColor) !important;
        }
        .header {
          color: var(--sfProfile_profileTextColor) !important;
        }
      }
      > .divider {
        border-top-color: var(--sfProfile_dividerColor) !important;
      }
      > .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--sfProfile_profileTextColor) !important;
        .icon {
          color: var(--sfProfile_socialIconColor) !important;
          &:not(.dropdown) {
            font-size: 1.5rem !important;
            line-height: 1;
          }
        }
        > span {
          line-height: 1;
          margin: 0;
        }
      }
    }
  }
}

// THEMES
.rootWrapper {
  // THEME 1
  &.theme-1 {
    // THEME 1 - PAGESCROLLED
    &.pageScrolled {
      .profileContainer.theme-1 {
        .profile-image {
          left: calc(var(--vw,1vw) * -20);
          @media only screen and (max-device-width:736px), (max-width:736px) {
            left: 50%;
          }
        }
        .profile-name {
          left: calc(calc(var(--vh,1vh) * -20) - 3.5rem);
          @media only screen and (max-device-width:736px), (max-width:736px) {
            left: 0;
          }
        }
        .toolbar-wrapper {
          margin-left: 0rem;
          width: calc(100%);
        }
      }
    }
  }
  // THEME 2
  &.theme-2 {
    .profileContainer.theme-2 {
      .profile-image {
        bottom: auto;
        top: calc(50% - 2rem);
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .profile-name {
        opacity: 1;
        left: 50%;
        transform: translateX(-50%);
        padding-right: 2rem;
        padding-left: 2rem;
      }
      .profile-social {
        left: 50%;
        transform: translateX(-50%);
      }
      .toolbar-wrapper {
        display: flex;
        justify-content: center;
        margin-left: 0;
        width: calc(100%);
      }
    }
    // THEME 2 - PAGESCROLLED
    &.pageScrolled {
      .profileContainer {
        .profile-image {
          top: calc(var(--vw,1vw) * -20);
        }
        .profile-name {
          opacity: 0;
        }
        .toolbar-wrapper {
          margin-left: 0rem;
          width: calc(100%);
        }
      }
    }
  }
  // THEME 3
  &.theme-3 {
    .profileContainer.theme-3 {
      display: grid;
      grid-template-columns: [profile] @sideBarWidth [toolbar] 6fr;
      grid-template-rows: [toolbar-row] 41px [content] 6fr [footer] 0;
      .profile {
        grid-column-start: profile;
        grid-column-end: span toolbar;
        grid-row-start: toolbar-row;
        grid-row-end: span footer;
        width: @sideBarWidth;
        height: calc(calc(var(--vh, 1vh) * 100) - @navBarHeight);
        top: @navBarHeight;
        position: fixed;
      }
      .toolbar {
        grid-column-start: toolbar;
        grid-column-end: span toolbar;
        grid-row-start: toolbar-row;
        grid-row-end: span content;
      }
      .content {
        grid-column-start: toolbar;
        grid-column-end: span toolbar;
        grid-row-start: content;
        grid-row-end: span footer;
      }
      .profile-cover {
        width: 100%;
        height: 100%;
      }
      .profile-image {
        bottom: auto;
        top: calc(50% - 10rem);
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .profile-name {
        opacity: 1;
        left: 50%;
        bottom: auto;
        top: calc(50%);
        transform: translate(-50%, -50%);
        padding-right: 2rem;
        padding-left: 2rem;
      }
      .profile-social {
        left: 50%;
        bottom: auto;
        top: calc(50% + 2rem);
        transform: translate(-50%, -50%);
      }
      .toolbar-wrapper {
        margin-left: 0;
        width: calc(100%);
        padding: 0 2rem;
      }

      > .content {
        min-height: calc(calc(var(--vh,1vh) * 100) - 60px - 3rem);

        .collectionViewer {
          .cover {
            left: @sideBarWidth;
          }
        }
      }
    }
  }
  // THEME RESPONSIVE
  &.theme-1, &.theme-2, &.theme-3 {
    .profileContainer {
      @media only screen and (min-device-width:240px) and (max-device-width:736px), (min-width:240px) and (max-width:812px) {
        display: block !important;
      }
      .profile {
        @media only screen and (min-device-width:240px) and (max-device-width:736px), (min-width:240px) and (max-width:812px) {
          top: auto !important;
          width: 100% !important;
          height: 220px !important;
          position: relative !important;
        }
      }
      .profile-cover {
        @media only screen and (min-device-width:240px) and (max-device-width:736px), (min-width:240px) and (max-width:812px) {
          height: 220px !important;
        }
      }
      .profile-image {
        @media only screen and (min-device-width:240px) and (max-device-width:736px), (min-width:240px) and (max-width:812px) {
          top: calc(50% - 2rem) !important;
          left: 50% !important;
          transform: translate(-50%, -50%) !important;
        }
      }
      .profile-name {
        @media only screen and (min-device-width:240px) and (max-device-width:736px), (min-width:240px) and (max-width:812px) {
          top: auto !important;
          left: 50% !important;
          transform: translateX(-50%) !important;
          bottom: 3rem !important;
        }
      }
      .profile-social {
        @media only screen and (min-device-width:240px) and (max-device-width:736px), (min-width:240px) and (max-width:812px) {
          top: auto !important;
          bottom: 0rem !important;
        }
      }
      .toolbar-wrapper {
        @media only screen and (min-device-width:240px) and (max-device-width:736px), (min-width:240px) and (max-width:812px) {
          display: flex;
          justify-content: center;
          margin-left: 0 !important;
          width: calc(100%) !important;
          padding: 0 2rem !important;
        }
      }
    }
  }
}

.searchModal {
  overflow: hidden;
  border-radius: 1rem !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px !important;
  overflow: visible !important;
  .content {
    background-color: transparent !important;
    overflow: visible !important;
  }
  
  @media only screen and (min-device-width:240px) and (max-device-width:736px), (min-width:240px) and (max-width:812px) {
    height: calc(calc(var(--vh, 1vh) * 100) - 2rem);
  }
}